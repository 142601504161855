
import {
  Component,
  Mixins,
  Watch,
} from 'vue-property-decorator';
import {
  worktables_store, attractions_store, global_store, auth_store,
} from '@/store';
import AttractionSharedCard from '@/modules/attractions/shared/components/AttractionSharedCard.vue';
import JumpIcon from '@/assets/icons/jump-icon.svg?inline';
import WorktableAttractionsSlider from '@/modules/attractions/shared/components/sliders/AttractionsSharedSlider.vue';
import PageNotFound from '@/layouts/components/PageNotFound.vue';
import { ErrorHandlereMixin } from '@/mixins';
import { Attractions } from '@/modules/attractions/types';
import WorktableDetailOptions from '../components/WorktableDetailOptions.vue';
import AddAttractionsToWorktableDialog from '../components/AddAttractionsToWorktableDialog.vue';
import { Worktables } from '../types';

const { state, actions } = worktables_store;

@Component({
  components: {
    JumpIcon,
    AttractionSharedCard,
    WorktableAttractionsSlider,
    WorktableDetailOptions,
    AddAttractionsToWorktableDialog,
    PageNotFound,
  },
})
export default class WorktableDetail extends Mixins(ErrorHandlereMixin) {
  created() {
    const { id } = this.$route.params;

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionCreatedEvent', async (
      data: {
        socket: unknown,
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionCreatedEvent - WorktableDetail.vue', model);
        attractions_store.mutations.ADD_ATTRACTION(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionUpdatedEvent', async (
      data: {
        socket: unknown,
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionUpdatedEvent - WorktableDetail.vue', model);
        attractions_store.mutations.UPDATE_ATTRACTION(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionDeletedEvent', (
      data: {
        socket: unknown;
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionDeletedEvent - WorktableDetail.vue', model);
        worktables_store.mutations.DELETE_ATTRACTION_IN_WORKTABLES(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('WorktableCreatedEvent', (
      data: {
        socket: unknown,
        model: Worktables.Worktable
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('WorktableCreatedEvent - WorktableDetail.vue', model);
        worktables_store.mutations.ADD_WORKTABLE(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('WorktableUpdatedEvent', (
      data: {
        socket: unknown,
        model: Worktables.Worktable
      },
    ) => {
      const { model } = data;

      if (model) {
        if (model.id === +id) {
          console.log('WorktableUpdatedEvent - WorktableDetail.vue', model);
          worktables_store.mutations.SET_CURRENT_WORKTABLE(model);
        }
        worktables_store.mutations.EDIT_WORKTABLE(model);
      }
    });
  }

  async mounted() {
    this.set_worktable_by_id();
  }

  edit_worktable_dialog_model = false;
  is_adding_attractions_dialog = false;
  attractions_loading = false;
  loading = true;

  get user_id() {
    return auth_store.state.user.id;
  }

  get attractions() {
    return attractions_store.state.attractions.reverse();
  }

  get worktable_attractions() {
    return this.worktable.attractions;
  }

  get date() {
    return global_store.state.date;
  }

  get worktable() {
    return state.current_worktable;
  }

  @Watch('date')
  on_date_change() {
    this.set_worktable_by_id();
  }

  async open_add_attractions_dialog() {
    try {
      this.attractions_loading = true;

      if (!attractions_store.state.attractions.length) {
        await attractions_store.actions.get_attractions();
      }

      this.attractions_loading = false;
      this.is_adding_attractions_dialog = true;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async add_attractions(data: []) {
    try {
      await actions.add_attractions_to_worktable({
        id: this.worktable.id,
        attractions: data,
      });

      this.is_adding_attractions_dialog = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async set_worktable_by_id() {
    try {
      this.loading = true;
      const { id } = this.$route.params;

      await actions.set_worktable_by_id(parseInt(id, 10));
      this.loading = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
