
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import AttractionSharedCard from '@/modules/attractions/shared/components/AttractionSharedCard.vue';
import { Timeslots } from '@/modules/timeslots/types';
import { Slider } from '@/components';
import { attractions_store } from '@/store';

@Component({
  components: {
    AttractionSharedCard,
    Slider,
  },
})
export default class WorktableAttractionsSlider extends Vue {
  @Prop({ type: Array, default: () => ([]) })
  slides!: unknown[];

  @Prop({ type: Array })
  timeslots!: Timeslots.Timeslot;

  currentIndex = 0;

  get swiperExpanded() {
    return attractions_store.state.attractions_expand;
  }

  set swiperExpanded(val) {
    this.swiperExpanded = val;
  }

  expand(index: number) {
    this.currentIndex = index;
    attractions_store.actions.toggle_expand();
  }
}
