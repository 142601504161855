
import { Vue, Component } from 'vue-property-decorator';
import NotFoundImg from '@/assets/404.svg?inline';

@Component({
  components: {
    NotFoundImg,
  },
})
export default class PageNotFound extends Vue {

}
