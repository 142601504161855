
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';
import {
  SimpleDialog,
  Table, Checkbox,
} from '@/components';
import { Attractions } from '@/modules/attractions/types';
import { worktables_store } from '@/store';

@Component({
  components: {
    SimpleDialog,
    Table,
    Checkbox,
  },
})
export default class AddAttractionsToWorktableDialog extends Vue {
  @Prop()
  items!: Attractions.Attraction[];

  is_error_message = false;

  get items_model() {
    return this.items.map((el) => ({
      ...el,
      selected: false,
    }));
  }

  get worktable_id() {
    return worktables_store.state.current_worktable.id;
  }

  attraction_checked(v: boolean, index: number) {
    this.items_model[index].selected = v;
    this.is_error_message = false;
  }

  headers = [
    {
      text: 'Название',
      value: 'title',
    },
    {
      text: '',
      value: 'selected',
    },
  ];

  submit() {
    const attractions = this.items_model.filter((el) => el.selected);

    if (attractions.length) {
      this.$emit('add_attractions', attractions);
    } else {
      this.is_error_message = true;
    }
  }
}
